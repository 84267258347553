import {
  MaterialTocUnitType,
  MaterialTocChapterType,
  MaterialTocType,
} from './types'

const checkIsTrialAllowed = (unit: MaterialTocUnitType) => {
  if (!unit.previewable) return false

  const { composableType, videoUrl, id } = unit

  switch (composableType) {
    case 'video':
      return !!videoUrl
    case 'question_group':
      return !!id
    default:
      return 'App-Only' as const
  }
}

export const processMaterialChapter = (chapter: MaterialTocChapterType) => {
  return chapter.children.map((section) => {
    let isAtLeastOneUnitTrialAllowed: boolean = false

    const units = section.children.map((unit) => {
      const isUnitTrialAllowed = checkIsTrialAllowed(unit)
      if (isUnitTrialAllowed && !isAtLeastOneUnitTrialAllowed) {
        isAtLeastOneUnitTrialAllowed = true
      }
      return {
        ...unit,
        isTrialAllowed: !!isUnitTrialAllowed,
        isAppOnlyTrialAllowed: isUnitTrialAllowed === 'App-Only',
      }
    })

    return {
      ...section,
      children: units,
      isTrialAllowed: isAtLeastOneUnitTrialAllowed,
    }
  })
}

export const getSectionUnitCount = (section: MaterialTocChapterType) => {
  return section.children.reduce(
    (totalChapterCount, unit) => totalChapterCount + unit.children.length,
    0
  )
}

export const getTocUnitCount = (
  materialsTocList: MaterialTocType[] | undefined | MaterialTocType
) => {
  if (!materialsTocList) return 0

  if (Array.isArray(materialsTocList)) {
    return materialsTocList.reduce((totalCount, materialsToc) => {
      return (
        totalCount +
        materialsToc.children.reduce(
          (sectionTotal, section) =>
            sectionTotal + getSectionUnitCount(section),
          0
        )
      )
    }, 0)
  } else {
    return materialsTocList.children.reduce(
      (sectionTotal, section) => sectionTotal + getSectionUnitCount(section),
      0
    )
  }
}
