import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { Text } from '../text'
import constructLocale, { LangType } from '../../utils/locale-constructor'
import themeVar from '../../theme/themeVar'

const locale = {
  en: {
    highlights: {
      title: 'You will learn in this course',
    },
    suitable_for: {
      title: 'This material is suitable for',
    },
  },
  'zh-TW': {
    highlights: {
      title: '上完課你會學到',
    },
    suitable_for: {
      title: '本教材適合',
    },
  },
}

const { LocaleProvider, useLocaleContext } = constructLocale({
  locales: locale,
})

export interface SuitableForComProps {
  highlights: { type: string; text: string }[]
  suitableFor: { title: string; description: string }[]
  lang: LangType
}

export const SuitableForBlockComp = ({
  suitableFor,
  highlights,
  lang,
}: SuitableForComProps) => {
  const translations = useLocaleContext()
  const processedHightlights = useMemo(() => {
    if (!highlights?.length) return []

    const temp: { title: string; descriptions: string[] }[] = []
    for (const highlight of highlights) {
      if (highlight.type === 'header') {
        temp.push({ title: highlight.text, descriptions: [] })
      } else {
        temp.at(-1)?.descriptions?.push?.(highlight.text)
      }
    }
    return temp
  }, [highlights])

  return (
    <LocaleProvider defaultLang={lang}>
      {processedHightlights.length > 0 ? (
        <div style={{ marginBottom: '2rem' }}>
          <Text
            component="h5"
            variant="heading_m_500"
            style={{ marginBottom: '0.75rem' }}
          >
            {translations.highlights.title}
          </Text>
          <ul
            style={{
              margin: 0,
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.75rem',
            }}
          >
            {processedHightlights.map(({ title, descriptions }, index) => {
              return (
                <Item
                  key={title}
                  index={index}
                  title={title}
                  descriptions={descriptions}
                />
              )
            })}
          </ul>
        </div>
      ) : null}
      {suitableFor?.length > 0 ? (
        <div>
          <Text
            component="h5"
            variant="heading_m_500"
            style={{ marginBottom: '0.75rem' }}
          >
            {translations.suitable_for.title}
          </Text>
          <ul
            style={{
              margin: 0,
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.75rem',
            }}
          >
            {suitableFor.map(({ title, description }, index) => (
              <Item
                key={title}
                index={index}
                title={title}
                descriptions={[description]}
              />
            ))}
          </ul>
        </div>
      ) : null}
    </LocaleProvider>
  )
}

function Item({
  index,
  title,
  descriptions,
}: {
  index: number
  title: string
  descriptions: string[]
}) {
  return (
    <li style={{ display: 'flex', gap: '0.5rem' }}>
      <Text
        variant="body_s_400"
        style={{
          backgroundColor: themeVar.color.palette['grayscale-50'],
          display: 'flex',
          height: '1.5rem',
          width: '1.5rem',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '9999px',
          marginTop: '0.125rem',
        }}
      >
        {index + 1}
      </Text>
      <div>
        <Text variant="body_m_500" component="h5">
          {title}
        </Text>
        {descriptions.map((description) => (
          <Text key={description} variant="body_s_400" component="p">
            {description}
          </Text>
        ))}
      </div>
    </li>
  )
}

export const Description = styled.div`
  padding-left: ${themeVar.distance.ml};
  padding-top: ${themeVar.distance.xs};
  font-size: ${themeVar.fontSize.sm};
  line-height: 1.5;
`
