const plugin = require('tailwindcss/plugin')
const { paletteColors } = require('../../libs/shared/assets/colors')

module.exports = {
  // we use CssBaseline from @material-ui/core, so we don't need the preflight
  corePlugins: {
    preflight: false,
  },
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      listStyleType: {
        circle: 'circle',
      },
      zIndex: {
        navbar: 1000,
        announcement: 1010,
        backdrop: 1020,
        'bottom-sheet': 1030,
        modal: 1040,
        dropdown: 1050,
        tooltip: 1060,
        toast: 1070,
      },
      screens: {
        sm: '600px',
        mobileUp: '768px',
        md: '900px',
        lg: '1200px',
        xl: '1536px',
      },
      transitionProperty: {
        height: 'height',
        spacing: 'margin, padding',
        padding: 'padding',
        minWidth: 'min-width',
        maxWidth: 'max-width',
        maxHeight: 'max-height',
        left: 'left',
        right: 'right',
        top: 'top',
        bottom: 'bottom',
        width: 'width',
      },
      colors: paletteColors,
      height: {
        'fit-content': 'fit-content',
      },
      cursor: {
        grab: 'grab',
      },
      animation: {
        'reverse-spin': 'reverse-spin 1s linear infinite',
        ...generateAnimations('fade-in'),
        ...generateAnimations('fade-in-up'),
        ...generateAnimations('x-scroll'),
        ...generateAnimations('x-scroll-reverse'),
        ...generateAnimations('from-bottom-to-top'),
      },
      keyframes: {
        'reverse-spin': {
          from: {
            transform: 'rotate(360deg)',
          },
        },
        'fade-in': {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 },
        },
        'fade-in-up': {
          '0%': { opacity: '0', transform: 'translate3d(0, 100%, 0)' },
          '100%': { opacity: '1', transform: 'none' },
        },
        'fade-in-quick': {
          '0%': { opacity: 0 },
          '33%': { opacity: 1 },
          '66%': { opacity: 1 },
          '100%': { opacity: 1 },
        },
        'x-scroll': {
          from: { transform: 'translateX(0)' },
          to: { transform: 'translateX(-100%) translateX(-1rem)' },
        },
        'x-scroll-reverse': {
          from: { transform: 'translateX(-100%) translateX(-1rem)' },
          to: { transform: 'translateX(0)' },
        },
        'from-bottom-to-top': {
          '0%': { transform: 'translateY(100%)', opacity: '0' },
          '100%': { transform: 'translateY(0)', opacity: '1' },
        },
      },
      backgroundImage: {
        'registration-page':
          'url(https://public.wordup.com.tw/shop/assets/sign+up+page/sign_up.png)',
        'password-page': 'url(https://public.wordup.com.tw/web-assets/forget-password/bg-tile.png)',
      },
      backgroundColor: {
        'rgb-gray': 'rgb(247, 248, 250)',
        'rgb-black': 'rgb(56, 59, 61)',
      },
      fontFamily: {
        noto: [`var(--font--noto-sans-tc)`, `var(--font--noto-sans)`, 'sans-serif'],
      },
    },
  },
  plugins: [
    plugin(({ addUtilities, addBase }) => {
      addUtilities({
        '.fade-right': {
          '-webkit-mask-image': 'linear-gradient(90deg, #000 90%, transparent)',
        },
        '.bottom-left-border': {
          borderLeft: '1px solid #d8d8d8',
          borderImage: 'linear-gradient(to top, #d8d8d8 50%, transparent 50%) 0% 1;',
        },
        '.top-left-border': {
          borderLeft: '1px solid #d8d8d8',
          borderImage: 'linear-gradient(to bottom, #d8d8d8 50%, transparent 50%) 0% 1;',
        },
        '.left-border': {
          borderLeft: '1px solid #d8d8d8',
        },
        '.hide-scrollbar': {
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
        '.break-word': {
          wordBreak: 'break-word',
        },
        ...generateClampLineStyles(5),
        '.hyphens-auto': {
          hyphens: 'auto',
        },
        '.shadow-custom': {
          'box-shadow': '0 0 10px 0 rgba(0,0,0,0.1)',
        },
        '.hide-dropdown-arrow': {
          '&::-ms-expand': {
            display: 'none',
          },
          '-webkit-appearance': 'none',
          '-moz-appearance': 'none',
        },
        '.no-text-decoration': {
          'text-decoration': 'none',
        },
        '.list-initial': {
          'list-style-type': 'initial',
        },
        '.custom-icon': {
          fontFamily: 'custom-icon !important',
          fontStyle: 'normal',
        },
        '.animation-paused': {
          animationPlayState: 'paused',
        },
        '.animation-running': {
          animationPlayState: 'running',
        },
        '.animation-reverse': {
          animationDirection: 'reverse',
        },
      })
      addBase({
        '.scrollbar::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
        '.scrollbar::-webkit-scrollbar': {
          height: '8px',
          width: '8px',
        },
        '.scrollbar::-webkit-scrollbar-thumb': {
          backgroundColor: '#eeefef',
          borderRadius: '16px',
          transition: 'background-color 5s ease-in-out',
        },
        '.scrollbar::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#dedfdf',
        },
        '.scrollbar::-webkit-scrollbar-track-piece': {
          background: 'transparent',
        },
      })
    }),
  ],
}

function generateClampLineStyles(lines) {
  const styles = {}

  for (let i = 1; i <= lines; i++) {
    styles[`.clamp-line-${i}`] = {
      display: '-webkit-box',
      '-webkit-line-clamp': `${i}`,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    }
  }

  return styles
}

function generateAnimations(animationName) {
  return {
    [`${animationName}-150`]: `${animationName} 0.15s`,
    [`${animationName}-300`]: `${animationName} 0.3s`,
    [`${animationName}`]: `${animationName} 0.5s`,
    [`${animationName}-700`]: `${animationName} 0.7s`,
    [`${animationName}-1000`]: `${animationName} 1s`,
  }
}
