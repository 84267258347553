import { isEmpty } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { IntercomProps, useIntercom } from 'react-use-intercom'

const INTERCOM_LIGHTWEIGHT_APP_LAUNCHER_CLASSNAME = 'intercom-lightweight-app-launcher' // seems like before interaction, the element with this className is rendered
const INTERCOM_LAUNCHER_CLASSNAME = 'intercom-launcher-frame' // after interaction, the element with this className is rendered
const INTERCOM_MESSENGER_CLASSNAME = 'intercom-messenger-frame'

const useWuIntercom = () => {
  const { show, hide, ...restIntercomObj } = useIntercom()

  const rebootIntercom = (props?: IntercomProps) => {
    restIntercomObj.shutdown()
    restIntercomObj.boot(props)
  }

  return {
    setShowIntercom,
    rebootIntercom,
    closeIntercomMessenger: hide,
    openIntercomMessenger: show,
    ...restIntercomObj,
  }
}

export default useWuIntercom

const setShowLauncher = (show: boolean) => {
  const launcherElements = [
    document.getElementsByClassName(
      INTERCOM_LIGHTWEIGHT_APP_LAUNCHER_CLASSNAME,
    )?.[0] as HTMLElement,
    document.getElementsByClassName(INTERCOM_LAUNCHER_CLASSNAME)?.[0] as HTMLElement,
  ].filter(e => e)

  if (isEmpty(launcherElements)) return

  launcherElements.forEach(launcherElement => {
    launcherElement?.style.setProperty(
      'transition',
      'opacity 300ms ease, visibility 300ms ease, transform 200ms ease',
      'important',
    )

    launcherElement.style.setProperty('visibility', show ? 'visible' : 'hidden', 'important')

    launcherElement.style.setProperty('opacity', show ? '1' : '0', 'important')

    launcherElement.style.setProperty('transform', show ? 'scale(1)' : 'scale(0)', 'important')
  })
}

const setShowModal = (show: boolean) => {
  const modalElement = document.getElementsByClassName(
    INTERCOM_MESSENGER_CLASSNAME,
  )?.[0] as HTMLElement
  if (!modalElement) return

  modalElement.style.setProperty(
    'transition',
    'opacity 300ms ease 0s, visibility 300ms ease 0s',
    'important',
  )

  modalElement.style.setProperty('visibility', show ? 'visible' : 'hidden', 'important')

  modalElement.style.setProperty('opacity', show ? '1' : '0', 'important')
}

export const setShowIntercom = (showIntercom: boolean) => {
  setShowLauncher(showIntercom)
  setShowModal(showIntercom)
}

export const useIntercomHider = ({ shouldHideIntercom }: { shouldHideIntercom: boolean }) => {
  const shouldHide = useMemo(() => {
    return shouldHideIntercom
  }, [shouldHideIntercom])

  useEffect(() => {
    setShowIntercom(!shouldHide)
  }, [shouldHide])
}
